var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    sublayer: _vm.id.includes('aqi_forecast'),
    layer: !_vm.id.includes('aqi_forecast'),
    small: _vm.small,
  },attrs:{"id":_vm.id}},[_c('span',{staticClass:"layer-title"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.toggleLayer(_vm.id)}}},[(_vm.layer.visible)?_c('span',[_vm._v("✓ ")]):_vm._e(),_c('span',{class:{
          visible: _vm.layer.visible,
        },domProps:{"innerHTML":_vm._s(_vm.layer.title)}})])]),(_vm.layer.visible && _vm.layer.blurb)?_c('span',{staticClass:"layer-blurb",domProps:{"innerHTML":_vm._s(_vm.layer.blurb)}}):_vm._e(),(_vm.controls && _vm.layer.visible)?_c('span',[_c('keep-alive',[_c(_vm.customConfigurationRenderer,{tag:"component",attrs:{"defaults":_vm.rendererDefaults},on:{"change":_vm.handleLayerConfigChange}})],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }