var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"content is-size-4"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('p',[_vm._v(" Copyright © "+_vm._s(_vm.year)+" University of Alaska Fairbanks. All rights reserved. ")]),_vm._m(4),_vm._m(5),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"data-credit"},[_vm._v(" Information about the current fire season comes from the "),_c('a',{attrs:{"href":"https://fire.ak.blm.gov/aicc.php"}},[_vm._v(" Alaska Interagency Coordination Center")]),_vm._v(" and the "),_c('a',{attrs:{"href":"https://mesowest.utah.edu"}},[_vm._v("MesoWest data services")]),_vm._v(". Smoke forecasts are provided by the "),_c('a',{attrs:{"href":"https://gmao.gsfc.nasa.gov/"}},[_vm._v("Global Modeling and Assimilation Office (GMAO)")]),_vm._v(" at NASA Goddard Space Flight Center and local air quality sensor data feeds are provided through "),_c('a',{attrs:{"href":"https://www2.purpleair.com/"}},[_vm._v("PurpleAir")]),_vm._v(". ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" VIIRS Active Fire Detections are provided by the "),_c('a',{attrs:{"href":"https://gina.alaska.edu"}},[_vm._v("Geographic Information Network of Alaska")]),_vm._v(" with support from "),_c('a',{attrs:{"href":"https://www.nesdis.noaa.gov/about/our-offices/office-of-low-earth-orbit-observations"}},[_vm._v("NOAA")]),_vm._v(" and the Bureau of Land Management’s "),_c('a',{attrs:{"href":"https://www.blm.gov/programs/fire-and-aviation/regional-info/alaska-fire-service"}},[_vm._v("Alaska Fire Service")]),_vm._v(". Data are accessed from the "),_c('a',{attrs:{"href":"https://fire.ak.blm.gov"}},[_vm._v("Alaska Interagency Coordination Center")]),_vm._v(". ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" This tool was developed by the "),_c('a',{attrs:{"href":"https://www.snap.uaf.edu/","title":"👍"}},[_vm._v("Scenarios Network for Alaska and Arctic Planning")]),_vm._v(" in collaboration with the "),_c('a',{attrs:{"href":"https://akcasc.org"}},[_vm._v("Alaska Climate Adaptation Science Center")]),_vm._v(", the University of Alaska Anchorage "),_c('a',{attrs:{"href":"https://www.uaa.alaska.edu/academics/college-of-health/departments/population-health-sciences/institute-for-circumpolar-health-studies"}},[_vm._v("Institute for Circumpolar Health Studies")]),_vm._v(", and the University of Washington "),_c('a',{attrs:{"href":"https://cdrc.uw.edu"}},[_vm._v("Center for Disaster Resilient Communities")]),_vm._v(". Development was partially funded by "),_c('a',{attrs:{"href":"https://cfpub.epa.gov/ncer_abstracts/index.cfm/fuseaction/display.abstractDetail/abstract_id/11349"}},[_vm._v("EPA Grant Number R840479")]),_vm._v(". SNAP is a research group in the "),_c('a',{attrs:{"href":"https://uaf-iarc.org/"}},[_vm._v("International Arctic Research Center")]),_vm._v(" at the "),_c('a',{attrs:{"href":"https://uaf.edu/uaf/"}},[_vm._v("University of Alaska Fairbanks")]),_vm._v(". ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Please contact "),_c('a',{attrs:{"href":"mailto:uaf-snap-data-tools@alaska.edu"}},[_vm._v("uaf-snap-data-tools@alaska.edu")]),_vm._v(" if you have questions or would like to provide feedback for this tool. "),_c('a',{attrs:{"href":"https://uaf-snap.org/tools-overview/"}},[_vm._v("Visit the SNAP Climate + Weather Tools page")]),_vm._v(" to see our full suite of interactive web tools. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" UA is an affirmative action / equal opportunity employer, educational institution and provider, and prohibits illegal discrimination against any individual. "),_c('a',{attrs:{"href":"https://www.alaska.edu/nondiscrimination/"}},[_vm._v("Statement of Nondiscrimination")]),_vm._v(" and "),_c('a',{attrs:{"href":"https://www.alaska.edu/records/records/compliance/gdpr/ua-privacy-statement/"}},[_vm._v("Privacy Statement")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" UA is committed to providing accessible websites. "),_c('a',{attrs:{"href":"https://www.alaska.edu/webaccessibility/"}},[_vm._v("Learn more about UA’s notice of web accessibility")]),_vm._v(". If we can help you access this website’s content, "),_c('a',{attrs:{"href":"mailto:uaf-snap-data-tools@alaska.edu"}},[_vm._v("email us!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo mt-6"},[_c('a',{attrs:{"href":"https://uaf.edu/uaf/"}},[_c('img',{attrs:{"src":require("@/assets/UAF.svg"),"alt":"UAF Logo"}})])])
}]

export { render, staticRenderFns }