var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loadingData)?_c('div',{staticClass:"spinner"},[_c('div')]):_vm._e(),(
      !_vm.loadingData && _vm.selected && !_vm.aqiForecastPresent && !_vm.dangerRatingPresent
    )?_c('div',{staticClass:"container"},[_c('h5',{staticClass:"title is-5"},[_vm._v(" No data available for "),_c('strong',[_vm._v(_vm._s(_vm.selected.name)+" "),(_vm.selected.alt_name)?_c('span',[_vm._v(" ("+_vm._s(_vm.selected.alt_name)+") ")]):_vm._e()])])]):_vm._e(),(!_vm.loadingData && _vm.selected)?_c('div',{staticClass:"container"},[_c('h5',{staticClass:"title is-5"},[_vm._v(" Current conditions for "),_c('strong',[_vm._v(_vm._s(_vm.selected.name)+" "),(_vm.selected.alt_name)?_c('span',[_vm._v(" ("+_vm._s(_vm.selected.alt_name)+") ")]):_vm._e()])]),_c('div',{staticClass:"content is-size-4"},[(_vm.dangerRatingPresent)?_c('p',[_c('a',{attrs:{"href":"https://en.wikipedia.org/wiki/National_Fire_Danger_Rating_System"}},[_vm._v("Fire danger")]),_vm._v(" level is "),_c('span',{staticClass:"fire-danger-rating",style:({
            backgroundColor: _vm.apiOutput.cfd.color,
            color:
              _vm.apiOutput.cfd.type === 'Low' || _vm.apiOutput.cfd.type === 'Extreme'
                ? '#fff'
                : '#000',
          })},[_vm._v(" "+_vm._s(_vm.apiOutput.cfd.type)+" ")]),_vm._v("  — "),_c('span',{staticClass:"fire-danger-level",domProps:{"innerHTML":_vm._s(_vm.dangerRating)}})]):_vm._e(),(_vm.aqiForecastPresent)?_c('table',{staticClass:"table"},[_c('caption',[_vm._v(" Air Quality Index forecast, "+_vm._s(_vm.selected.name)+" "),(_vm.selected.alt_name)?_c('span',[_vm._v(" ("+_vm._s(_vm.selected.alt_name)+") ")]):_vm._e()]),_vm._m(0),_c('tbody',[_c('tr',[_c('td',{style:({
                backgroundColor: _vm.aqiName(_vm.apiOutput.aqi_6.aqi).bg,
                color: _vm.aqiName(_vm.apiOutput.aqi_6.aqi).fg,
              })},[_vm._v(" "+_vm._s(_vm.apiOutput.aqi_6.aqi)+" — "+_vm._s(_vm.aqiName(_vm.apiOutput.aqi_6.aqi).name)+" ")]),_c('td',{style:({
                backgroundColor: _vm.aqiName(_vm.apiOutput.aqi_12.aqi).bg,
                color: _vm.aqiName(_vm.apiOutput.aqi_12.aqi).fg,
              })},[_vm._v(" "+_vm._s(_vm.apiOutput.aqi_12.aqi)+"— "+_vm._s(_vm.aqiName(_vm.apiOutput.aqi_12.aqi).name)+" ")]),_c('td',{style:({
                backgroundColor: _vm.aqiName(_vm.apiOutput.aqi_24.aqi).bg,
                color: _vm.aqiName(_vm.apiOutput.aqi_24.aqi).fg,
              })},[_vm._v(" "+_vm._s(_vm.apiOutput.aqi_24.aqi)+"— "+_vm._s(_vm.aqiName(_vm.apiOutput.aqi_24.aqi).name)+" ")]),_c('td',{style:({
                backgroundColor: _vm.aqiName(_vm.apiOutput.aqi_48.aqi).bg,
                color: _vm.aqiName(_vm.apiOutput.aqi_48.aqi).fg,
              })},[_vm._v(" "+_vm._s(_vm.apiOutput.aqi_48.aqi)+"— "+_vm._s(_vm.aqiName(_vm.apiOutput.aqi_48.aqi).name)+" ")])])])]):_vm._e(),(_vm.historicalFiresPresent)?_c('div',[_c('p',[_vm._v("Historical fires in this place:")]),_c('ul',_vm._l((_vm.apiOutput.hist_fire),function(year,fire){return _c('li',{key:fire},[_c('strong',[_vm._v(_vm._s(fire)+",")]),_vm._v(" "+_vm._s(year)+" ")])}),0)]):_vm._e(),(_vm.nearbyFiresPresent)?_c('div',[_c('p',[_vm._v(" There are "),_c('strong',[_vm._v(_vm._s(_vm.nearbyFiresCount)+" active fires")]),_vm._v(" within ~70 miles of this location. ")]),_c('table',{staticClass:"table"},[_vm._m(1),_c('tbody',_vm._l((_vm.nearbyFires),function(fire){return _c('tr',{key:fire.NAME},[_c('td',[_vm._v(_vm._s(fire.properties.NAME))]),_c('td',[_vm._v(_vm._s(_vm.formatNumber(fire.properties.acres)))]),_c('td',[_vm._v(_vm._s(_vm.formatDate(fire.properties.updated)))])])}),0)])]):_c('div',[(_vm.selected.region == 'Alaska')?_c('span',[_vm._v(" There are no active fires within ~70 miles of this location. ")]):_vm._e()])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("6-hour AQI")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("12-hour AQI")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("24-hour AQI")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("48-hour AQI")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Fire name")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Size (acres)")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Last updated")])])])
}]

export { render, staticRenderFns }